<template>
  <div class="container mt-5 ">

  </div>
</template>

<script>
import {localize} from 'vee-validate';

export default {
  name: "Home",
  data() {
    return {

    }
  },
  created() {
    localize('en')
  },
  mounted() {
    this.$router.push("/dashboard")
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
